import { Component, ViewChild, ViewContainerRef, OnInit, ComponentFactoryResolver } from '@angular/core';
import { NotificationService, NcalayerService, AuthService } from '../../../core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentProcessingService } from '../../../core/services/document-processing.service';
import { DocumentCardViewComponent } from '../../card-types/document-card-component/view/document-card-view.component';
import { MatDialog } from '@angular/material';

import { Subscription, of } from 'rxjs';
import { SignEntity } from '../../../../app/core/models/signEntity';
import { DocumentMediator } from '../../services/document-mediator.service';
import { MediatorRequest } from '../../../../app/core/mediator/IMediatorRequest';
import { ModalDialogService } from '../../../../app/shared/services/modal-dialog.service';
import {
    RejectDocumentDialogComponent,
    RejectDocumentRequest
} from '../../../../app/core/shared-component/dialogs/reject-document/reject-document.component';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'document-view-page',
    templateUrl: './document-view-page.component.html',
    styleUrls: ['./document-view-page.component.scss'],
})
export class DocumentViewPageComponent implements OnInit {
    @ViewChild('documentCardViewComponent')
    documentCardViewComponent: DocumentCardViewComponent;
    @ViewChild('documentTypeViewComponent', { read: ViewContainerRef })
    documentTypeViewComponent: ViewContainerRef;
    public canBeSigned: boolean;
    public canBeEdited: boolean;
    public canBeRejected: boolean;
    public canBeRetired: boolean;
    private docTypeComponentRef: any;
    private subscriptions: Subscription[] = [];
    private currentDocumentType: string;
    private uniqueId: string;
    constructor(
        private factoryResolver: ComponentFactoryResolver,
        private documentProcessing: DocumentProcessingService,
        private ncalayer: NcalayerService,
        private notification: NotificationService,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        private mediator: DocumentMediator,
        private modalDialogService: ModalDialogService
    ) { }

    ngOnInit(): void {
        const subs = this.activatedRoute.params.subscribe(roueteParams => {
            this.currentDocumentType = roueteParams.documentType;
            this.uniqueId = roueteParams.uniqueCode;
            const type = this.documentProcessing.getViewDocumentDetailsComponent(this.currentDocumentType);
            const docTypeFactory = this.factoryResolver.resolveComponentFactory(<any>type);
            this.docTypeComponentRef = docTypeFactory.create(this.documentTypeViewComponent.parentInjector);
            this.documentTypeViewComponent.clear();
            this.documentTypeViewComponent.insert(this.docTypeComponentRef.hostView);

            const cards = this.loadCard();
            this.subscriptions.push(cards);
        });
        this.subscriptions.push(subs);
    }

    public edit() {
        this.router.navigate([`/cabinet/edit/${this.currentDocumentType}/${this.uniqueId}`]);
    }

    public rejectDocument() {
        const modal = this.modalDialogService.show2(RejectDocumentDialogComponent, {
            centered: true, windowClass: 'modal-630',
        }, {
            uniqueId: this.uniqueId
        });
        modal[1].getResult()
            .pipe(switchMap((d: RejectDocumentRequest) => {
                if (d) {
                    return this.documentProcessing.reject(d);
                } else {
                    modal[0].close();
                    return of(null);
                }
            })).subscribe((res) => {
                var textNotificationToShow = 'Документ отклонен';
                if (res.error != null) {
                    textNotificationToShow = res.error.text;
                }
                this.notification.showNotification(textNotificationToShow, () => {
                    this.router.navigate(['/cabinet/viewer/inbox/onsigning']);
                });
                modal[0].close();
            })
    }

    loadCard() {
        return this.documentProcessing.loadCardInfo(this.uniqueId).subscribe(loadCardResult => {
            this.canBeSigned = loadCardResult.data.canBeSigned;
            this.canBeEdited = loadCardResult.data.canBeEdited;
            this.canBeRejected = loadCardResult.data.canBeRejected;
            this.canBeRetired = loadCardResult.data.canBeRetired;
            this.mediator.publish(new MediatorRequest('setCardInfo', loadCardResult));
        });
    }

    signAndSend() {
        const documentCount = this.docTypeComponentRef.instance.getCountDocument();
        if (this.currentDocumentType !== 'avr') {
            if (documentCount < 1) {
                this.notification.showNotification('Количество файлов не должно быть меньше 1');
                return;
            }
        }
        this.documentProcessing.getSignEntity(this.uniqueId).subscribe(x => {
            if (x.error != null) {
                this.notification.showNotification(x.error.text);

                return;
            }
            this.sign(x.data);

        });
    }

    sign(entity: SignEntity) {
      const text = entity.xmlData;
      const encoder = new TextEncoder();

      const encodedText = encoder.encode(text);
      const base64ToSign = btoa(String.fromCharCode(...Array.from(encodedText)));

      this.ncalayer.createCMSSignatureFromBase64(base64ToSign, false, 'SIGNATURE', entity.lastChainSignature).subscribe(
        result => {
          console.log(result);
          this.send(<string>result.body.result[0]
            .replace("-----BEGIN CMS-----", '')
            .replace("-----END CMS-----", '').trim());
          },error => {
          this.notification.showSnackNotification(this.ncalayer.getLocalizedErrorMessage(error));
        });
  }

  send(signedXml: string): void {
    this.documentProcessing.sendToSign2(this.uniqueId, signedXml).subscribe(response => {
      if (response.error != null) {
        this.notification.showNotification(response.error.text);
        return;
      }
      this.canBeEdited = false;
      this.canBeSigned = false;
      this.canBeRejected = false;
      this.canBeRetired = false;
      this.notification.showNotification('Документ успешно подписан', () => {
        // this.router.navigate(['/cabinet']);
        this.authService.setBreadcrumb('Исходящие', 'На подписании');
        // this.authService.getTabs().splice(1)
        // tabs[0].status=1;
        this.authService.tabs = this.authService.getTabs().filter(f => f.code == this.uniqueId);

                this.mediator.send(new MediatorRequest('changeSignedStatus', this.canBeEdited));
                // TODO: click
                this.loadCard();

            });
        });
    }

    retired() {
        if (confirm(`Вы действительно хотите отозвать документ: ${this.uniqueId}?`)) {
            this.documentProcessing.retired(this.uniqueId).subscribe(response => {
                if (response.error != null) {
                    this.notification.showNotification(response.error.text);
                    return;
                }
                this.canBeEdited = false;
                this.canBeSigned = false;
                this.canBeRejected = false;
                this.canBeRetired = false;
                this.notification.showNotification('Документ отозван', () => {
                    this.router.navigate(['/cabinet/viewer/outgoing/onsigning']);
                });
            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
    }
}
